import React from 'react'
import { helpers } from '@common'
import styles from './style.m.scss'
import Icon from '@pc/components/Icon'

const className = helpers.classNames.react(styles)

// 给中间弹窗使用
export const ModalTitle = (props) => {
  // closable 控制x展示 默认展示
  const { platform, closable = true, leftContent } = props

  return (
    <div
      {...className('modal-title-wrapper', {
        'modal-title-wrapper-pc': platform === 'pc',
      })}
    >
      {leftContent ? (
        <span {...className('modal_title_left')}>{leftContent}</span>
      ) : null}
      <p {...className('modal-title')}>{props.title}</p>
      {closable && (
        // <div {...className('modal-title-close')} >
        <Icon
          {...className('modal-title-close-x')}
          icontype="svg"
          name="danchuangchahao"
          onClick={props.onClick}
        />
        // </div>
      )}
    </div>
  )
}

// 给h5 的底部半弹窗使用
export const ModalTitlePop = (props) => {
  // closable 控制x展示 默认展示
  const { platform, closable = true, leftContent, borderBottom = false } = props

  return (
    <div
      {...className('popup_title_wrapper', {
        'popup-title-wrapper-pc': platform === 'pc',
        border_bottom: borderBottom,
      })}
    >
      {leftContent ? (
        <span {...className('popup_title_left')}>{leftContent}</span>
      ) : (
        <span />
      )}
      <span {...className('popup_title font-lato-bol')}>{props.title}</span>
      {closable && (
        <Icon
          {...className('popup_title_close')}
          icontype="svg"
          name="danchuangchahao"
          onClick={props.onClick}
        />
      )}
    </div>
  )
}
