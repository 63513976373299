import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { classNames } from '@common/helpers'
import { css, js } from '@common/helpers/CDN'
import styles from './style.m.scss'

import _ from 'lodash'
const loadSource = _.once(() => {
  js('//at.alicdn.com/t/font_814114_ut6pohuefk.js')
  css('//at.alicdn.com/t/font_814114_ut6pohuefk.css')
})

const className = classNames.react(styles)

export default class Icon extends PureComponent {
  static propsTypes = {
    icontype: PropTypes.oneOf(['iconfont', 'image', 'svg']).isRequired,
    name: PropTypes.oneOf(['iconfont', 'svg']) && PropTypes.isRequired,
    src: PropTypes.oneOf(['image']) && PropTypes.isRequired,
  }

  render() {
    let { name, icontype, src, alt = '' } = this.props

    if (icontype === 'svg') {
      loadSource()
      return (
        <svg
          aria-hidden="true"
          {...this.props}
          {...className('icon', this.props.className)}
        >
          <use xlinkHref={`#openpay-${name}`} />
        </svg>
      )
    }

    if (icontype === 'iconfont') {
      loadSource()
      return (
        <i
          {...this.props}
          {...className('iconfont', `icon-${name}`, this.props.className)}
        />
      )
    }

    if (icontype === 'image') {
      return (
        <img
          src={src}
          alt={alt}
          {...this.props}
          {...className('icon', this.props.className)}
        />
      )
    }
  }
}
